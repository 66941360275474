// FireSprinkler.tsx

import React from "react";

const EmergencyExist: React.FC = () => {
  return (
    <div className="container p-3 md:p-14 bg-gray-100">
      <h1 className="text-4xl font-bold mb-6 text-[#E78600] flex justify-center">
        Emergency Exits and Safety Guidelines
      </h1>
      <div className=" md:flex">
        <p className="text-gray-700 text-lg py-2 md:px-5 font-semibold">
          Emergency exits are critical safety components in any building,
          designed to provide a clear and accessible path to safety during
          emergencies such as fires, natural disasters, or other hazardous
          situations. Properly marked and well-maintained emergency exits ensure
          occupants can evacuate quickly and safely, reducing the risk of injury
          or fatalities.
        </p>
        <img src="/assets/image7.webp" className="md:w-[53%] rounded-2xl"></img>
      </div>
      <section className="mt-8">
        <h2 className="text-2xl font-semibold mb-4 text-[#E78600] ">
          Key Features of Emergency Exits:
        </h2>
        <p>
          A sprinkler system is designed to use water to put out a fire. Between
          a fire suppression system and a traditional sprinkler system we have
          your residential, commercial building and industrial. There are four
          different types as follows:
        </p>
        <ul className="list-disc ml-6 text-gray-600">
          <li>
            {" "}
            <strong>Accessibility: </strong> Emergency exits must be
            unobstructed and accessible at all times. This includes ensuring
            doors can be easily opened from the inside without the use of keys,
            special tools, or complex mechanisms.
          </li>
          <li>
            <strong>Visibility:</strong> Exit doors should be clearly marked
            with illuminated or reflective signs that are visible in low-light
            or smoke-filled conditions. These signs typically read "EXIT" and
            may include arrows pointing toward the direction of the exit path.
          </li>
          <li>
            <strong> Regulations and Standards:</strong> Emergency exits must
            comply with local and national building codes, fire safety
            regulations, and Occupational Safety and Health Administration
            (OSHA) standards, which dictate their location, size, and number
            based on the building's occupancy and design.
          </li>
          <li>
            <strong> Emergency Lighting: </strong>In the event of a power outage
            or other emergency situations, backup lighting should illuminate all
            exit routes to guide occupants safely. This lighting must be tested
            and maintained regularly.
          </li>
        </ul>
      </section>

      <section>
        <div className="bg-[#E78600] p-8 rounded-lg shadow-md  mx-auto mt-8">
          <h3 className="text-2xl font-semibold mb-4 text-white">
            Maintenance and Inspection of Emergency Exits
          </h3>
          <p className="text-black mb-6">
            To ensure that emergency exits remain functional and safe at all
            times, the following measures must be taken:
          </p>

          <ul className="list-disc list-inside text-gray-700 space-y-4">
            <li className="text-black">
              <span className="font-semibold text-white">
                Regular Inspections:
              </span>{" "}
              Emergency exits should be inspected regularly to ensure they are
              unobstructed, accessible, and compliant with all safety
              regulations. This includes checking that exit doors open easily
              and that all signage is clearly visible and properly illuminated.
            </li>
            <li className="text-black">
              <span className="font-semibold text-white">
                Fire Drills and Training:
              </span>{" "}
              Conduct regular fire drills to familiarize building occupants with
              emergency exits and evacuation procedures. This practice helps
              ensure that everyone knows what to do and where to go during an
              emergency.
            </li>
            <li className="text-black">
              <span className="font-semibold text-white">
                Signage Maintenance:
              </span>{" "}
              Emergency exit signs and directional markers must be checked
              regularly to ensure they are well-lit, legible, and pointing in
              the correct direction. Any faulty lights or signs should be
              replaced immediately.
            </li>
            <li className="text-black">
              <span className="font-semibold text-white">Clear Pathways:</span>{" "}
              Ensure that corridors, stairwells, and doorways leading to
              emergency exits are free of obstacles such as furniture,
              equipment, or debris. Obstructing an exit route can delay
              evacuation and increase the risk of injury during emergencies.
            </li>
            <li className="text-black">
              <span className="font-semibold text-white">
                Backup Power for Emergency Lighting:
              </span>{" "}
              Emergency lighting systems should be connected to backup power
              sources, such as batteries or generators, to ensure that they
              function even during power outages. Routine checks should be
              conducted to ensure that backup systems are operational.
            </li>
          </ul>
        </div>
      </section>

      <section>
        <div className=" md:flex">
          <div className="bg-[#2E2828] p-8 rounded-lg shadow-md max-w-3xl mx-auto mt-8">
            <h2 className="text-2xl font-bold mb-6 text-white">
              Emergency Exit Guidelines
            </h2>
            <ol className="list-decimal ml-6 space-y-4 text-white">
              <li>
                <span className="font-semibold text-white">
                  Remain Calm and Act Quickly:
                </span>
                In any emergency, staying calm is key. Upon hearing an alarm or
                noticing signs of danger (e.g., smoke, fire, or structural
                damage), immediately stop what you're doing and prepare to
                evacuate. Avoid panicking, as this can slow down the evacuation
                process and increase risk.
              </li>
              <li>
                <span className="font-semibold text-white">
                  Know the Location of Emergency Exits:
                </span>
                Familiarize yourself with the layout of the building,
                particularly the locations of emergency exits. You should always
                know at least two exits from any given area in case one is
                blocked or unsafe. Emergency exit maps are often posted in
                common areas, hallways, and near fire alarms.
              </li>
              <li>
                <span className="font-semibold text-white">
                  Follow Emergency Exit Signs:
                </span>
                Emergency exits are marked with clear "EXIT" signs, often
                illuminated or with reflective materials. Follow these signs to
                the nearest exit. If an exit is blocked or inaccessible due to
                smoke or debris, proceed to the next closest exit.
              </li>
              <li>
                <span className="font-semibold text-white">
                  Use Stairs, Avoid Elevators:
                </span>
                In most emergency situations, especially fires, elevators should
                not be used as they may become trapped or malfunction. Instead,
                use the stairs to reach the ground level safely. Stairs are
                designed to be safer during fires, as they are generally built
                in fire-resistant enclosures.
              </li>
              <li>
                <span className="font-semibold text-white">
                  Assist Others if Possible:
                </span>
                If it is safe to do so, help others evacuate, especially those
                with disabilities or mobility issues. Guide them to the nearest
                emergency exit or alert emergency personnel if they require
                additional assistance.
              </li>
              <li>
                <span className="font-semibold text-white">
                  Stay Low in Smoke-filled Areas:
                </span>
                If you encounter smoke, crouch low to the ground where the air
                is clearer, and cover your nose and mouth with a cloth if
                possible. Smoke rises, and the lower you are, the easier it will
                be to breathe and see as you evacuate.
              </li>
              <li>
                <span className="font-semibold text-white">
                  Do Not Re-enter the Building:
                </span>
                Once you have evacuated the building, do not attempt to re-enter
                until emergency services have declared it safe. Going back
                inside could expose you to new dangers or hinder the efforts of
                firefighters and rescue personnel.
              </li>
            </ol>
          </div>
          <div className="flex justify-center items-center p-4 rounded-2xl">
            <img src="/assets/image3.webp" className="rounded-2xl "></img>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EmergencyExist;
