import React from "react";
import logo from "./logo.svg";
import "./App.css";
import LandingPage from "./views/landingPage/landingPage";
import Footer from "./shared/footer/footer";
import Navbar from "./shared/navBar/navbar";
import AboutPage from "./views/about/aboutPage";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import FireSprinkler from "./views/fireSprinkler/fireSprinkler";
import EmergencyExist from "./views/emergencyExit/emergencyExit";
import ContactUs from "./views/contact/contact";

function App() {
  return (
    <Router>
      <div className="h-[100vh]">
        <Navbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/fireSprinkler" element={<FireSprinkler />} />
          <Route path="/emergencyExist" element={<EmergencyExist />} />
          <Route path="/contactUs" element={<ContactUs />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
