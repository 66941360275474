import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'

export default function Navbar() {
  return (
    <Disclosure as="nav" className="bg-[#2E2828] shadow">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 justify-between">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            {/* Mobile menu button */}
            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-[#E78600] hover:text-white focus:outline-none focus:ring-2">
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
              <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
            </DisclosureButton>
          </div>
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center">
             <a href="/" className='cursor-pointer text-xl md:text-4xl font-semibold text-[#E78600]'>Genius Fire</a>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
              {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
              <a
                href="/"
                className="inline-flex text-white items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium  hover:border-[#E78600] hover:text-white"
              >
                Home
              </a>
              <a
                href="/about"
                className="inline-flex text-white items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium  hover:border-[#E78600] hover:text-white"
              >
                About
              </a>
              <a
                href="/fireSprinkler"
                className="inline-flex text-white items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium  hover:border-[#E78600] hover:text-white"
              >
                Fire Sprinkler
              </a>
              <a
                href="/emergencyExist"
                className="inline-flex text-white items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium  hover:border-[#E78600] hover:text-white"
              >
                Emergency Exist
              </a>
        
              <a
                href="/contactUs"
                className="inline-flex text-white items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium  hover:border-[#E78600] hover:text-white"
              >
                Contact Us
              </a>
            </div>
          </div>
          
        </div>
      </div>

      <DisclosurePanel className="sm:hidden">
        <div className="space-y-1 pb-4 pt-2">
          {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
          <DisclosureButton
            as="a"
            href="/"
            className="block border-l-4 border-white bg-[#E78600] py-2 pl-3 pr-4 text-base font-medium text-white"
          >
            Home
          </DisclosureButton>
          <DisclosureButton
            as="a"
            href="/about"
            className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-white hover:border-[#E78600] hover:bg-gray-50 hover:text-gray-700"
          >
            About
          </DisclosureButton>
          <DisclosureButton
            as="a"
            href="/fireSprinkler"
            className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-white hover:border-[#E78600] hover:bg-gray-50 hover:text-gray-700"
          >
            Fire Sprinkler
          </DisclosureButton>
          <DisclosureButton
            as="a"
            href="/emergencyExist"
            className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-white hover:border-[#E78600] hover:bg-gray-50 hover:text-gray-700"
          >
            Emergency Exist
          </DisclosureButton>
          <DisclosureButton
            as="a"
            href="/contactUs"
            className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-white hover:border-[#E78600] hover:bg-gray-50 hover:text-gray-700"
          >
            Contact Us
          </DisclosureButton>
        </div>
      </DisclosurePanel>
    </Disclosure>
  )
}
