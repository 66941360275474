// FireSprinkler.tsx

import React from "react";
import { BuildingOffice2Icon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'

const ContactUs: React.FC = () => {
    return (
        <div className="relative isolate bg-white">
          <div className="mx-auto grid grid-cols-1 lg:grid-cols-2">
            <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-32">
              <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
                  <svg
                    aria-hidden="true"
                    className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                  >
                    <defs>
                      <pattern
                        x="100%"
                        y={-1}
                        id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                        width={200}
                        height={200}
                        patternUnits="userSpaceOnUse"
                      >
                        <path d="M130 200V.5M.5 .5H200" fill="none" />
                      </pattern>
                    </defs>
                    <rect fill="white" width="100%" height="100%" strokeWidth={0} />
                    <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                      <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                    </svg>
                    <rect fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" width="100%" height="100%" strokeWidth={0} />
                  </svg>
                </div>
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Get in touch</h2>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                Your safety is our priority, and we’re here to help ensure your property and loved ones are protected. Whether you have questions about our fire precautionary solutions, need assistance with fire safety planning, or want to schedule an inspection, our team is ready to assist. Reach out today, and we’ll respond promptly to address your needs. Together, we can build a safer, more secure environment.
                </p>
                <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600">
                  <div className="flex gap-x-4">
                    <dt className="flex-none">
                      <span className="sr-only">Address</span>
                      <BuildingOffice2Icon aria-hidden="true" className="h-7 w-6 text-gray-400" />
                    </dt>
                    <dd>
                    Musaffah Musaffah 039 building
                      <br />
                      Abu Dhabi, UAE
                    </dd>
                  </div>
                  <div className="flex gap-x-4">
                    <dt className="flex-none">
                      <span className="sr-only">Telephone</span>
                      <PhoneIcon aria-hidden="true" className="h-7 w-6 text-gray-400" />
                    </dt>
                    <dd>
                      <a href="tel:+(971) 56 544 9291" className="hover:text-gray-900">
                        +(971) 56 544 9291
                      </a>
                    </dd>
                  </div>
                  <div className="flex gap-x-4">
                    <dt className="flex-none">
                      <span className="sr-only">Email</span>
                      <EnvelopeIcon aria-hidden="true" className="h-7 w-6 text-gray-400" />
                    </dt>
                    <dd>
                      <a href="mailto:mustafarao410@gmail.com" className="hover:text-gray-900">
                      info@geniusfire.ae
                      </a>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
           <div >
                <img src="/assets/image8.jpg" className="w-full h-full"></img> 
           </div>
          </div>
        </div>
      )
   
};

export default ContactUs;
