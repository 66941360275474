import React from "react";
import Navbar from "../../shared/navBar/navbar";
import DetailSection from "../../shared/detailSection/detailSection";

function LandingPage() {
  return (
    <div className="landing-page">
      {/* cover photo */}
      <div
        style={{
          backgroundImage: "url('/assets/banner-1.jpg')",
          height: "80vh",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="w-full pl-11 md:pl-44 pt-28 text-white">
          <div className="flex leading-none justify-start  text-[40px] md:text-[65px]  font-extrabold ">
            We're here to <br></br> Save Lives and<br></br> Properties
          </div>
          <p className=" font-semibold text-white mt-4 ">
            {" "}
            We save people, animals and place each and every day and we are
            looking <br></br> to give better service in future too.
          </p>
          <a href="/about">
          <div className="bg-[#E78600] hover:opacity-50 w-fit mt-4 p-4 rounded-md cursor-pointer ">
            Discover More
          </div>
          </a>
        </div>
      </div>

{/* 
      <div className="container mx-auto mt-5">
        <div className="flex justify-center">
          <div className="w-full max-w-3xl">
            <article className="relative p-8 bg-white shadow-lg hover:shadow-xl transition-shadow duration-300">
              <div className="relative overflow-hidden">
              <div
                className="relative h-72 bg-cover bg-center"
                style={{ backgroundImage: "url('http://demo.yolotheme.com/html/motor/images/demo/demo_131.jpg')" }}
              >
                <div className="absolute inset-0 bg-black opacity-0 hover:opacity-90 transition-opacity duration-300"></div>
              </div>
              </div>
              <div className="absolute top-4 left-4 bg-gray-100 border-4 border-gray-300 text-center font-bold p-2 transform scale-50 opacity-0  transition-opacity duration-300 hover:opacity-100 hover:scale-100">
                <span className="block text-xl">11</span>
                <span className="block text-sm">JAN</span>
              </div>
              <div className="mt-8">
                <h5 className="text-2xl font-bold transition-colors duration-300 hover:text-[#E78600]">HARVICK GETS WHAT HE NEEDS, JOHNSON AMONG THOSE</h5>
                <p className="mt-4 flex items-center space-x-4 text-gray-700">
                  <a href="#" className="flex items-center text-gray-500 hover:text-[#E78600] transition-colors"><i className="fa fa-pencil-square-o text-[#E78600]"></i> Tony Jahson</a>
                  <a href="#" className="flex items-center text-gray-500 hover:text-[#E78600] transition-colors"><i className="fa fa-comments-o text-[#E78600]"></i> 150</a>
                </p>
                <p className="mt-4 text-gray-600">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Doloremque vero libero voluptatibus earum? Alias dignissimos quo cum, nulla esse facere atque, blanditiis doloribus at sunt quas, repellendus vel? Et, hic!</p>
                <a href="#" className="inline-flex items-center mt-4 px-6 py-3 bg-white text-gray-700 font-bold hover:bg-[#E78600] hover:text-white transition-colors">
                  <i className="fa fa-long-arrow-right mr-2 bg-[#E78600] p-2 text-white"></i>READ MORE
                </a>
              </div>
            </article>
          </div>
        </div>
      </div> */}


      {/* detail section - Think before ot starts */}
      <div className="py-20 md:px-16 ">
        <div className=" text-[30px] md:text-[45px] px-3 font-bold text-[#382E26] justify-center items-center flex ">
          Think of fire before it Starts
        </div>
        <div className="grid  grid-cols-1 mt-3 px-10 md:grid-cols-4 sm:grid-cols-2 gap-10 pt-10">
          <div className="justify-center flex-col items-center flex">
            <img src="/assets/section1image.PNG" className="h-[72px]" alt="Fire Prevention" />
            <p className="font-bold py-3">Fire Preventions</p>
            <div className="flex justify-center items-center w-full h-full text-center">
              The precautions to prevent potentially harmful fires, surviving
              and reducing the damage caused by emergencies.
            </div>
          </div>
          <div className="justify-center flex-col items-center flex">
            <img src="/assets/section2image.PNG" alt="Fire Prevention" />
            <p className="font-bold py-3">Department Information</p>
            <div className="flex justify-center items-center w-full h-full text-center">
            We provide you with practical actions, advice and resources to
            prepare for and cope during a range of emergencies.
            </div>
          </div>
          <div className="justify-center flex-col items-center flex">
            <img
              src="/assets/section3image.PNG"
              className="h-[74px]"
              alt="Fire Prevention"
            />
            <p className="font-bold py-3">Public Education</p>
            <div className="flex justify-center items-center w-full h-full text-center">
            The tools and information for teaching people of all ages about
            the fire and how to make choices regarding safety.
            </div>
          </div>
          <div className="justify-center flex-col items-center flex">
            <img src="/assets/section4image.PNG" className="h-[72px]" alt="Fire Prevention" />
            <p className="font-bold py-3">Stratagic Plan</p>
            <div className="flex justify-center items-center w-full h-full text-center">
            The plan focuses on implementing advanced technologies, improving
            services and resiliency during emergency events.
            </div>
          </div>
        </div>
      </div>

      <DetailSection></DetailSection>

      {/* Section ----- Category */}
      <div className="pb-20 px-16 ">
        <div className=" text-[30px] md:text-[45px] font-bold text-[#382E26] justify-center items-center flex ">
          Categories
        </div>
      </div>

      <div className=""
        style={{
          backgroundImage: "url('/assets/category.PNG')",
          height: "50vh",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          overflow: "hidden"
        }}
      >
      </div>


      {/* section 3 - youth FIRE stop prevention */}
      <div className=""
        style={{
          backgroundImage: "url('/assets/image1.jpg')",
          height: "50vh",
          width: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          overflow: "hidden"
        }}
      >
        <div className="w-full pl-11 md:pl-44 pt-28 text-[#382E26]">
          <div className="flex leading-none justify-start  text-[20px] md:text-[40px]  font-extrabold ">
          Youth Fire Stop Prevention & <br></br>Intervention Program
          </div>
          <p className=" font-semibold text-gray-600 mt-4 ">
            {" "}
            We save people, animals and place each and every day and we are
            looking <br></br> to give better service in future too.
          </p>
          <a href="/about">
          <div className="bg-[#E44426] hover:opacity-60 text-white w-fit mt-4 p-4 rounded-md cursor-pointer ">
            Discover More
          </div>
          </a>
        </div>
      </div>

     
    </div>
  );
}

export default LandingPage;
