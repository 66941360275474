import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from "@heroicons/react/20/solid";

const features = [
  {
    name: "Fire Safety Checklist",
    description:
      "Conduct regular inspections to identify and mitigate fire hazards. Ensure all safety protocols are up-to-date and effectively communicated.",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Fire Extinguishers",
    description:
      "Place extinguishers in easily accessible locations throughout your premises. Train employees on their proper use to ensure quick response during an emergency.",
    icon: LockClosedIcon,
  },
  {
    name: "Emergency Exits",
    description:
      "Clearly mark all emergency exits and keep pathways free of obstructions. Regularly test and maintain exit lights and signs to ensure visibility in all conditions.",
    icon: ServerIcon,
  },
];

export default function DetailSection() {
  return (
    <div className="bg-white py-24">
      <div className="mx-auto max-w-[90rem] sm:px-6 lg:px-8">
        <div className="relative isolate overflow-hidden bg-[#2E2828] px-6 py-20 sm:rounded-3xl sm:px-10 sm:py-24 lg:py-24 xl:px-24">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center lg:gap-y-0">
            <div className="lg:row-start-2 lg:max-w-md">
              <h2 className="text-3xl font-bold tracking-tight text-[#E78600] sm:text-4xl">
                Enhance Your Safety.
                <br />
                Implement Fire Precautions Today.
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-300">
              Equip your space with the tools and knowledge needed to prevent fire incidents.
              </p>
            </div>
            <img
              alt="Product screenshot"
              src="/assets/about-1.jpg"
              className="relative -z-20 h-[864px] min-w-full max-w-xl rounded-xl shadow-xl ring-1 ring-white/10 lg:row-span-4 lg:w-[64rem] lg:max-w-none"
            />
            <div className="max-w-xl lg:row-start-3 lg:mt-10 lg:max-w-md lg:border-t lg:border-white/10 lg:pt-10">
              <dl className="max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative">
                    <dt className=" inline-block font-semibold text-[#E78600]">
                     
                      {feature.name}
                    </dt>{" "}
                      <br></br>
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div
            aria-hidden="true"
            className="pointer-events-none absolute left-12 top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-3xl lg:bottom-[-12rem] lg:top-auto lg:translate-y-0 lg:transform-gpu"
          >
            <div
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
              className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-25"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
